.listed-card {
	
	transition: all .3s ease-out;
	
	& > .header {
		padding: 1.4rem 2rem;
		border-bottom: 1px solid #fff;
		text-align: left;
	}
	
	& > .body {
		padding: 2rem;
		text-align: left;
	}
	
}