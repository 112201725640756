/*
	@mixin font(
	$style: normal,
	$variant: normal,
	$weight: 400,
	$size: 1em,
	$line-height: 1.2em,
	$font: "Gibson",
	$color: #000) 
	
*/



// ---------- Headers ------------- //

// Header One

h1,
.header-one {
	
	font-family: 'Cormorant Garamond', 'Garamond', serif;
	font-weight: 500;
	font-size: 2.8rem;
	line-height: 1em;
	letter-spacing: .012em;
	color: $navy;
	
}

// Header Two

h2,
.header-two {
	
	font-family: 'Gibson', 'Helvetica', sans-serif;
	font-weight: 300;
	font-size: 1.75rem;
	line-height: 1.1em;
	letter-spacing: .033em;
	color: $navy;
	
}

// Header Three

h3,
.header-three {
	
	font-family: 'Gibson', 'Helvetica', sans-serif;
	font-weight: 400;
	font-size: 1.56rem;
	line-height: 1.12;
	letter-spacing: .033em;
	color: $navy;
	
}

// Header Four

h4,
.header-four {
	
	font-family: 'Cormorant Garamond', 'Garamond', serif;
	font-weight: 500;
	font-size: 1.1rem;
	line-height: 1.5em;
	letter-spacing: .012em;
	color: $navy;
	
	&.-number {
		font-size: 1.25rem;
		text-transform: uppercase;
	}
	
}

// Header Five

h5,
.header-five {
	
	font-family: 'Gibson', 'Helvetica', sans-serif;
	font-weight: 400;
	font-size: 400;
	line-height: 1.75em;
	letter-spacing: .033em;
	color: #fff;
	
}

// Body

p,
.paragraph {
	
	font-family: 'Gibson', 'Helvetica', sans-serif;
	font-weight: 300;
	font-size: 1rem;
	line-height: 1.375em;
	letter-spacing: .033em;
	color: $navy;
	
}

@media only screen and (max-width: 640px) {
	p,
	.paragraph {
		font-size: 1.1rem;
	}
}






// ---------- Variants ------------- //

// Styles
.-underline {
	position: relative;
	padding-bottom: 2rem;
	
	&:after {
		display: block;
		content: '';
		position: absolute;
			bottom: 0;
			left: 0;
		height: 1px;
		width: 100%;
		background: $orange;
	}
	
	&.-after-navy {
		&:after {
			background: $navy;
		}
	}
}

@media only screen and (min-width: 640px) {
	.-underline {
		&:after {
			width: 90%;
		}
	}
}

.-center {
	@extend .text-center;
	
	&:after {
		left: 50%;
		transform: translateX(-50%);
	}
}

// Colors
.-color {
	
	&-navy {
		color: $navy;
	}
	&-orange {
		color: $orange;
	}
	&-red {
		color: $red;
	}
	&-grey {
		color: $grey;
	}
	&-dark-grey {
		color: $dark-grey;
	}
	&-white {
		color: $white;
	}
	
}

.-title {
	text-transform: capitalize;
}

strong,
.-thicc {
	font-weight: 400;
}

.-light {
	font-weight: 300;
}

.-dec-underline {
	text-decoration: underline;
}

// ---------- Other Tags ------------- //

ul {
	list-style-type: none;
	margin-left: 1rem;
	@extend .paragraph;
	
	& > li {
		margin-bottom: 1rem;
		
		&:before {
			display: inline-block;
			content: '•';
			color: $red;
			margin-left: -1rem;
			margin-right: .4rem;
		}
	}
}

ol {
	margin-left: 1rem;
	@extend .paragraph;
	
	& > li {
		margin-bottom: 1rem;

	}
}