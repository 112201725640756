.bg-image {
	
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	
	&.-circle {
		width: 100%;
		padding-bottom: 100%;
		border-radius: 1200px;
	}
	
}