// Circular Std
@font-face {
  font-family: 'Gibson';
  src: url('fonts/Gibson-Light.eot');
  src: url('fonts/Gibson-Light.eot?#iefix') format('embedded-opentype'),
       url('fonts/Gibson-Light.svg#CircularStd-Book') format('svg'),
       url('fonts/Gibson-Light.ttf') format('truetype'),
       url('fonts/Gibson-Light.woff') format('woff'),
       url('fonts/Gibson-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Gibson';
  src: url('fonts/Gibson-Regular.eot');
  src: url('fonts/Gibson-Regular.eot?#iefix') format('embedded-opentype'),
       url('fonts/Gibson-Regular.svg#CircularStd-Bold') format('svg'),
       url('fonts/Gibson-Regular.ttf') format('truetype'),
       url('fonts/Gibson-Regular.woff') format('woff'),
       url('fonts/Gibson-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Gibson';
  src: url('fonts/Gibson-Regular.eot');
  src: url('fonts/Gibson-Regular.eot?#iefix') format('embedded-opentype'),
       url('fonts/Gibson-Regular.svg#CircularStd-Bold') format('svg'),
       url('fonts/Gibson-Regular.ttf') format('truetype'),
       url('fonts/Gibson-Regular.woff') format('woff'),
       url('fonts/Gibson-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}


// Fonts
@mixin font(
$style: normal,
$variant: normal,
$weight: 400,
$size: 1em,
$line-height: 1.2em,
$font: "Gibson",
$color: #000) {
	
	font: $style $variant $weight #{$size}/#{$line-height} $font;
	color: $color;
	text-transform: none;
	
	&.-white {
		color: #fff;
	}
	&.-light-grey {
		color: $light-grey;
	}
	&.-medium-grey {
		color: $medium-grey;
	}
	&.-green {
		color: $green;
	}
	&.-book {
		font-weight: 400;
	}
	&.-bold {
		font-weight: 500;
	}
	&.-lowercase {
		text-transform: none;
	}
	&.-uppercase {
		text-transform: uppercase;
	}
}

// Font-face