
@mixin transition(
$property: all,
$time: .6s,
$easing: cubic-bezier(0.165, 0.84, 0.44, 1) ) {
	transition: $property $time $easing;
}

.shrink-trans {
	transform: scale(1);
}

.transform-container {
	overflow: hidden;
}

.clip-path-right,
.clip-path-left,
.clip-path-up,
.clip-path-down {
	clip-path: polygon(0% 0%, 200% 0%, 200% 200%, 0% 200%);
	transition: all .6s cubic-bezier(0.165, 0.84, 0.44, 1);
	overflow: visible;
}

.transform {
	
	&-up,
	&-right,
	&-down {
		transform: translate(0px,0px);
		transition: all .9s cubic-bezier(0.165, 0.84, 0.44, 1);
	}
	&-center-up {
		transform: translate(-50%, -50%);
		transition: all .9s cubic-bezier(0.165, 0.84, 0.44, 1);
	}
}

.grow-after {
	
	&:after {
		transform-origin: left;
		transform: scaleX(1);
		transition: all 1.2s cubic-bezier(0.165, 0.84, 0.44, 1);
	}
	&.-center {
		&:after {
			transform: scaleX(1) translateX(-50%);
		}
	}
}

.fade-up,
.fade-left,
.fade-right,
.fade-down {
	transform: translateY(0px);
	opacity: 1;
	@include transition(
	$time: 1.2s,
	$easing: cubic-bezier(0.165, 0.84, 0.44, 1));
}

.-animation-trigger,
.-animation-hold {
	
	// After grow
	.grow-after {
		&:after {
			transform: scaleX(0);
		}
		&.-center {
			&:after {
				transform: scaleX(0) translateX(-50%);
			}
		}
	}
	
	// Clip path animation
	.clip-path-right,
	&.clip-path-right {
		clip-path: polygon(0% 0%, 0% 0%, 0% 200%, 0% 200%);
	}
	.clip-path-left,
	&.clip-path-left {
		clip-path: polygon(200% 0%, 200% 0%, 200% 200%, 200% 200%);
	}
	.clip-path-up,
	&.clip-path-up {
		clip-path: polygon(0% 200%, 200% 200%, 200% 200%, 0% 200%);
	}
	.clip-path-down,
	&.clip-path-down {
		clip-path: polygon(0% 0%, 200% 0%, 200% 0%, 0% 0%);
	}
	
	.transform {
		&-right {
			transform: translate(-100%, 0%);
		}
		&-up {
			transform: translate(-0%, 100%);
		}
		&-down {
			transform: translate(-0%, -30px);
		}
		&-center-up {
			transform: translate(-50%, 100%);
		}
	}
	
	// Move and fade in animation
	.fade-up,
	&.fade-up {
		transform: translateY(3rem);
		opacity: 0;
	}
	.fade-left,
	&.fade-left {
		transform: translateX(3rem);
		opacity: 0;
	}
	.fade-right,
	&.fade-right {
		transform: translateX(-3rem);
		opacity: 0;
	}
	.fade-down,
	&.fade-down {
		transform: translateY(-3rem);
		opacity: 0;
	}
	
	// Grow and shrink in animatino
	.shrink-trans {
		transform: scale(1.2);
	}
}

@media only screen and (max-width: 640px) {
	
	.clip-path-right\:sm,
	.clip-path-left\:sm,
	.clip-path-up\:sm,
	.clip-path-down\:sm {
		clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
		transition: all .6s cubic-bezier(0.165, 0.84, 0.44, 1);
		overflow: visible;
	}
	
	.-animation-trigger {
		
		// Clip path animation
		.clip-path-right\:sm,
		&.clip-path-right\:sm {
			opacity: .5;
			clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
		}
		.clip-path-left\:sm,
		&.clip-path-left\:sm {
			clip-path: polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%);
		}
		.clip-path-up\:sm,
		&.clip-path-up\:sm {
			clip-path: polygon(0% 200%, 200% 200%, 100% 100%, 0% 100%);
		}
		.clip-path-down\:sm,
		&.clip-path-down\:sm {
			clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
		}
		
		// Move and fade in animation
		.fade-up\:sm,
		&.fade-up\:sm {
			transform: translateY(3rem);
			opacity: 0;
		}
		.fade-left\:sm,
		&.fade-left\:sm {
			transform: translateX(3rem);
		}
		.fade-right\:sm,
		&.fade-right\:sm {
			transform: translateX(-3rem);
		}
		.fade-down\:sm,
		&.fade-down\:sm {
			transform: translateX(-3rem);
		}
		
	}
	
}

.image-container {
	position: relative;
	overflow: hidden;
	
	& > .image-inner {
		transition: all .1s ease-out;
	}
}

@media only screen and (min-width: 640px) {
	
	.clip-path-right\:md,
	.clip-path-left\:md,
	.clip-path-up\:md,
	.clip-path-down\:md {
		clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
		transition: all .9s cubic-bezier(0.165, 0.84, 0.44, 1);
		overflow: visible;
	}

	
	.-animation-trigger {
		.clip-path-right\:md,
		&.clip-path-right\:md {
			clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
		}
		.clip-path-left\:md,
		&.clip-path-left\:md {
			clip-path: polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%);
		}
		.clip-path-up\:md,
		&.clip-path-up\:md {
			clip-path: polygon(0% 200%, 200% 200%, 100% 100%, 0% 100%);
		}
		.clip-path-down\:md,
		&.clip-path-down\:md {
			clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
		}
		.fade-up\:md,
		&.fade-up\:md {
			transform: translateY(3rem);
			opacity: 0;
		}
		.fade-left\:md,
		&.fade-left\:md {
			transform: translateX(3rem);
		}
		.fade-right\:md,
		&.fade-right\:md {
			transform: translateX(-3rem);
		}
		.fade-down\:md,
		&.fade-down\:md {
			transform: translateX(-3rem);
		}
	}
}

.split-fade {
	opacity: 1;
	transform: translateY(0px);
	transition: all 3s cubic-bezier(0.075, 0.82, 0.165, 1);
	
	&.-hold {
		opacity: 0;
		transform: translateY(-10px);
	}
}

@for $i from 1 through 20 {
	
	.transition-delay-#{$i} {
		transition-delay: #{$i/10}s;
	}

}