.comparison-timeline {
	@extend .grid-x;
	padding: 6rem 10vw;
	
	& > .-left {
		text-align: right;
		background: #f2f2f2;
		margin-top: 3rem;
	}
	& > .-right {
		text-align: left;
		border: 1px solid $navy;
	}
}

.compare-before,
.compare-after {
	text-align: center;
	padding: 3rem;
	@extend .grid-y;
	@extend .grid-frame;
	overflow: visible !important;
	
	.paragraph {
		max-width: 300px;
		text-align: center;
		display: block;
		
		&.-thicc {
			margin-bottom: 2rem;
			font-size: 1.2rem;
		}
	}
}


.line-container {
	max-width: 300px;
	width: 100%;
	height: 100%;
	text-align: center;
	display: inline-block;
	position: relative;
	
	& > .line {
		height: 100%;
		min-height: 100px;
		background: $grey;
		width: 2px;
		display: inline-block;
	}
	
	& > .image {
		position: absolute;
			top: -3rem;
			right: -18vw;
			
		&.-top {
			top: -10rem;
		}
		&.-right {
			right: -23vw;
		}
		&.-bottom {
			bottom: -10rem;
		}
		&.-left {
			left: -23vw;
		}
	}
}

.compare-before {
	text-align: right;
	display: inline-block;
}
.compare-after {
	text-align: left;
	display: inline-block;
}

@media only screen and (min-width: 640px) {
	.comparison-timeline {
		& > .-left {
			border-right: 1px solid $navy;
			background: #fff;
			margin-top: 0rem;
		}
		& > .-right {
			border: none;
		}
	}
}