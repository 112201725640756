body {
	overflow-x: hidden;
	background: #f4f4f4 !important;
	&.-locked {
		overflow: hidden;
		display: block;
	}
	&.is-loading {
		& * {
			transition: all 0s !important;
		}
	}
}
main {
	overflow-x: hidden;
	overflow-y: hidden;
	
	max-width: 1920px;
	margin: 0 auto;
}

html {
	font-size: 100%;
}
@media only screen and (min-width: 640px) {
	html {
		font-size: 105%;
	}
}
@media only screen and (min-width: 1026px) {
	html {
		font-size: 110%;
	}
}
@media only screen and (min-width: 1336px) {
	html {
		font-size: 115%;
	}
}