.dropdown {
	margin-top: 3rem;
	cursor: pointer;
	
	& > .header {
		padding-right: 30%;
		padding-bottom: 1.5rem;
		position: relative;
		
		&:after {
			position: absolute;
			display: block;
			content: "";
				bottom: 0;
				left: 0;
			width: 100%;
			height: 1px;
			background: $grey;
		}
		
		& > .plus {
			height: 25px;
			width: 25px;
			position: absolute;
				right: 3rem;
				top: 50%;
			transform: translateY(calc(-50% - 1.5rem));
			
			&:after {
				display: block;
				content: "";
				position: absolute;
					top: 0;
					left: 50%;
				transform: translateX(-50%);
				width: 1px;
				height: 100%;
				background: $navy;
				transform: scaleY(1);
				transform-origin: bottom;
				transition: all .5s ease-out;
			}
			&:before {
				display: block;
				content: "";
				position: absolute;
					top: 50%;
					left: 0%;
				transform: translateY(-50%);
				width: 100%;
				height: 1px;
				background: $navy;
			}
		}
		
		& > h3 {
			font-weight: 300;
		}
	}
	
	& > .body {
		padding-top: 1.5rem;
		padding-right: 0%;
		max-height: 0px;
		overflow: hidden;
		transition: all .5s ease-out;
	}
	
	
	&.-active {
		
		& > .header {
			& > .plus {
				&:after {
					transform: scaleY(0);
				}
			}
		}
		
		& > .body {
			max-height: 400px;
		}
	}
}


@media only screen and (min-width: 640px) {
	.dropdown {
		& > .body {
			padding-right: 30%;
		}
	}
}