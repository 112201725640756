.main-header {
	
	position: fixed;
	z-index: 600;
		top: 0;
		left: 0;
	width: 100%;
	padding: 1.5rem 8vw;
	transition: all .3s ease-out;
	background: rgba(255, 255, 255, 0.7);
	text-align: left;
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
	
	& .-color {
		display: none;
	}
	& .-white {
		display: block;
	}
	
	&.-solid {
		position: static;
		background: #fff;
		
		& > .container {
			position: static;
		}
		& .-color {
			display: block;
		}
		& .-white {
			display: none;
		}
	}
	
	&.-solid {
		background: #fff;
	}
	&.-no-bg {
		background: transparent;
	}
	&.-hidden {
		transform: translateY(-110%);
	}
	
	.paragraph {
		margin-bottom: 0px;
	}
	.button {
		margin-left: 1rem;
	}
}

.fixed-header {
	position: fixed;
	z-index: 600;
		top: 0;
		left: 0;
	width: 100%;
	background: #fff;
	padding: 0rem 8vw;
	transform: translateY(-100%);
	transition: all .3s ease-out;
	
	&.-active {
		transform: translateY(0%);
	}
	.header-logo {
		max-width: 300px;
		margin: 0 auto;
	}
}

.hamburger {
	width: 30px;
	cursor: pointer;
}

.header-logo {
	width: 75%;
	max-width: 300px;
	margin: 0 auto;
}

.loader-overlay {
	position: fixed !important;
		z-index: -1;
	width: 100%;
	height: 100%;
	position: relative;
	clip-path: polygon(0% 200%, 200% 200%, 200% 200%, 0% 200%);
	transition: all .9s cubic-bezier(0.86, 0, 0.07, 1), transform 0s linear .9s;
	background: #f4f4f4;
	transform: translateY(150%);
	
	& > .icon {
		position: absolute;
			top: 50%;
			left: 50%;
		transform: translate(-50%, -50%);
		width: 20vw;
		min-width: 200px;
		transition: all 1.2s cubic-bezier(.27,.88,.49,1.33);
	}
	
	&.-animation-trigger {
		& > .icon {
			transform: translate(-50%, calc(-50% + 2rem));
			opacity: 0;
		}
	}
	
	&.-loading {
		z-index: 999;
		clip-path: polygon(0% 0%, 200% 0%, 200% 200%, 0% 200%);
		transform: translateY(0%);
	}
}

.is-loading {
	.loader-overlay {
		opacity: 1 !important;
	}
}

.main-menu {
	z-index: 602;
	position: fixed;
		top: 0;
		left: 0;
	width: 90%;
	height: 100%;
	background: #f2f2f2;
	margin-top: 0px;
	padding-top: 3rem;
	padding-right: 8vw;
	padding-left: 8vw;
	transform: translateX(-120%);
	transition: all .8s cubic-bezier(0.165, 0.84, 0.44, 1);
	
	.paragraph {
		display: block;
		margin-bottom: 1rem;
		padding-left: 0px;
	}
	.logo {
		width: 40px;
		display: inline-block;
	}
	& > .menu-header {
		padding-bottom: 3rem;
		border-bottom: 1px solid $navy;
		margin-bottom: 3rem;
	}
	.cross {
		cursor: pointer;
		width: 20px;
		height: 20px;
	}
	&.-is-active {
		transform: translateX(0%);
	}
}

.page-overlay {
	position: fixed;
	z-index: 601;
		top: 0;
		left: 0;
	width: 100%;
	height: 100%;
	background: #000;
	opacity: 0;
	transform: translateX(-120%);
	transition: opacity .7s ease-out;
	
	&.-is-active {
		opacity: .4;
		transform: translateX(0%);
	}
}

.menu-header {
	h1 {
		font-size: 1.8rem;
	}
}

@media only screen and (min-width: 640px) {
	.main-header {
		text-align: center;
	}
	.header-logo {
		width: 80%;
		max-width: 400px;
	}
}