.about-grid {
	@extend .grid-x;
	margin-left: -1rem;
	margin-right: -1rem;
	padding: 6rem 8vw;
}

.team-headshot {
	@extend .cell;
	width: calc(100% - 2rem);
	margin-left: 1rem;
	margin-right: 1rem;
	margin-bottom: 3rem;
	
	& > img {
		width: 100%;
	}
}

@media only screen and (min-width: 640px) {
	.team-headshot {
		width: calc(50% - 2rem);
	}
	.about-grid {
		padding: 6rem 15vw;
	}
}

@media only screen and (min-width: 1026px) {
	.team-headshot {
		width: calc(25% - 2rem);
	}
}

.team-bio {
	position: fixed;
	z-index: 9000;
		top: 0;
		left: 0;
	width: 100%;
	height: 100%;
	transform: translateX(100%);
	transition: all .8s cubic-bezier(.86,0,.07,1);
	
	& > .bio-overlay {
		background: rgba(10, 10, 10, 0.5);
		opacity: .5;
		transform: scaleX(0);
		transform-origin: right;
		transition: all .6s ease-out;
	}
	& > .bio-content {
		background: #fff;
	}
	.grid-x {
		height: 100%;
	}
	
	&.-active {
		transform: translateX(0%);
		
		& > .bio-overlay {
			transform: scaleX(1);
		}
	}
}

.bio-content {
	position: relative;
	overflow: auto;
	max-height: 100vh;
	
	& > .cross {
		cursor: pointer;
		position: absolute;
			top: 3rem;
			left: 3rem;
		width: 15px;
		height: 15px;
	}
	& > .grid-x {
		max-height: 100vh;
	}
}

.bio-line {
	width: 100%;
	height: 1px;
	background: $orange;
	margin-top: 3rem;
	margin-bottom: 3rem;
}
.team-bio-content {
	padding: 6rem;
	overflow: auto;
	max-height: 100%;
}