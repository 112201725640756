section {
	background: #fff;
}

.-bg {
	
	&-navy {
		background: $navy !important;
	}
	&-orange {
		background: $orange !important;
	}
	&-red {
		background: $red !important;
	}
	&-grey {
		background: $grey !important;
	}
	&-dark-grey {
		background: $dark-grey !important;
	}
	&-white {
		background: $white !important;
	}
	
}

body {
	&.-is-locked {
		overflow: hidden;
		display: block;
	}
}

@media only screen and (min-width: 640px) {
	section {
		padding: 6rem 0vw;
	}
}