// Links
.link {
	
	font-family: 'Gibson', 'Helvetica', sans-serif;
	font-weight: 300;
	font-size: 1rem;
	letter-spacing: .033em;
	color: $navy;
	position: relative;
	padding-bottom: 10px;
	background: transparent;
	overflow: visible;
	
	&:after {
		display: inline-block;
		content: "";
		position: absolute;
		z-index: 1;
			bottom: 6px;
			left: 0;
		width: 100%;
		height: 1px;
		background: $navy;
		transition: all .3s ease-out;
		opacity: 1;
		transform: translateY(0px);
	}
	
	&.-big {
		@extend .header-three;
	}
	
	&.-color-navy {
		color: $navy;
		&:after {
			background: $navy;
		}
		
		&:hover {
			color: $orange;
			&:after {
				background: $orange;
			}
		}
	}
	&.-color-orange {
		color: $orange
		&:after {
			background: $orange;
		}
	}
	&.-color-red {
		color: $red;
		&:after {
			background: $red;
		}
	}
	&.-color-grey {
		color: $grey;
		&:after {
			background: $grey;
		}
	}
	&.-color-dark-grey {
		color: $dark-grey;
		&:after {
			background: $dark-grey;
		}
		
		&:hover {
			color: $orange;
			&:after {
				background: $orange;
			}
		}
	}
	&.-color-white {
		color: #fff;
		&:after {
			background: $white;
		}
		
		&:hover {
			color: #aaa;
			&:after {
				background: #aaa;
			}
		}
	}
	
	&:hover {
		color: $orange;
		opacity: 1;
		
		&:after {
			background: $orange;
			transform: translateY(2px);
		}
	}
	
	&:active {
		&:after {
			transform: translateY(-1px);
		}
	}
}

a {
	transition: all .3s ease-out;
	
	&:hover {
		color: $orange;
		opacity: .6;
	}
	
	&.-color-white {
		&:hover {
			color: $red;
		}
	}
}

.button {
	border: 1px solid $dark-grey;
	padding: .4rem 1.2rem;
	@extend .paragraph;
	color: $dark-grey;
	cursor: pointer;
	transition: all .3s ease-out;
	display: inline-block;
	
	&:hover,
	&.-active {
		border: 1px solid $orange;
		background: $orange;
		color: #fff;
		opacity: 1;
	}
	&.-white {
		border: 1px solid $white;
		color: $white;
		
		&:hover {
			background: #fff;
			color: $orange;
		}
		&.-solid {
			background: #fff;
			color: $navy;
			
			&:hover {
				background: transparent;
				color: #fff;
			}
		}
	}
	&.-navy {
		background: $navy;
		color: #fff;
		border: 1px solid $navy;
		
		&.-outline {
			background: transparent;
			color: $navy;
			
			&:hover,
			&.-active {
				background: $navy;
				color: #fff;
			}
		}
	}
	&.-red {
		background: $red;
		border: 1px solid $red;
		color: #fff;
		
		&:hover {
			background: transparent;
			color: $red;
		}
	}
	&.-outline {
		border: 1px solid $dark-grey;
		color: $dark-grey;
		transition: all .3s ease-out;
		
		&:hover {
			background: $orange;
			color: #fff;
			border-color: $orange;
		}
	}
}



// --------- Inputs ----------- //
.select-container {
	position: relative;
	margin-bottom: 3rem;
	
	& > .arrow {
		display: block;
		position: absolute;
			top: 50%;
			right: 0;
		width: 15px;
		transform: translateY(-50%);
	}
}

select {
	border-bottom: 1px solid $dark-grey;
	color: $dark-grey !important;
	margin-bottom: 0px !important;
	
	&:hover {
		color: $navy;
		border-bottom: 1px solid $navy;
	}
}

textarea {
	width: 100%;
	margin-bottom: 2rem;
}

input[type="radio"] {
	border-radius: 1000px;
	border: 1px solid $navy;
	appearance: none;
	height: 15px;
	width: 15px;
	margin-left: 2rem;
	
	&:checked {
		background: $navy;
		box-shadow: none;
		outline: none;
	}
	+ label {
		margin-left: 1rem;
	}
	
	&:first-of-type {
		margin-left: 0px;
	}
}

input[type="text"],
input[type="email"],
select {
	
	appearance: none;
	border: 0px;
	border-bottom: 1px solid $dark-grey;
	padding: 5px 0px;
	background: transparent;
	border-radius: 0px;
	@extend .paragraph;
	margin-bottom: 3rem;
	transition: all .3s ease-out;
	color: #000;
	
	&::Placeholder {
		color: $dark-grey;
		transition: all .3s ease-out;
	}
	
	&:hover {
		border-bottom: 1px solid $navy;
		color: $navy;
		
		&::Placeholder {
			color: $navy;
		}
	}
	
	&:focus {
		border: none;
		border-bottom: 1px solid $navy;
		outline: none;
		box-shadow: none;
		color: #000;
		
		&::Placeholder {
			color: $grey;
		}
	}
	
	&.-orange {
		border-bottom: 1px solid $orange;
		color: $orange;
		
		&::Placeholder {
			color: $orange;
		}
	}
}

textarea {
	padding: 1rem;
	appearance: none;
	resize: vertical;
	@extend .paragraph;
	border: 1px solid $dark-grey;
	transition: all .3s ease-out;
	outline: none;
	box-shadow: none;
	
	&:hover {
		border: 1px solid $navy;
		background: #f6f6f6;
	}
	&:focus {
		outline: none;
		border: 1px solid $navy;
		box-shadow: none;
		margin: 0px;
		outline-offset: 0px;
		background: #fff;
	}
}

button {
	cursor: pointer;
	
	&:active {
		outline: none;
		box-shadow: none;
	}
}

.bx-pager-item {
	display: inline-block;
	margin-left: .5rem;
	color: #dddeda;
	
	a {
		display: inline-block;
		color: transparent;
		height: 15px;
		width: 15px;
		border: 1px solid $navy;
		border-radius: 100%;
		transition: all .3s ease-out;
		
		&:hover,
		&.active {
			background: $navy;
		}
	}
}
.bx-pager {
	text-align: right;
}