.page-banner {
	
	height: 65vh;
	min-height: 30rem;
	max-height: 1080px;
	position: relative;
	overflow: hidden;
	@include bg-image();
	@extend .grid-x;
	
	&.-centered-both {
		@extend .align-middle;
		@extend .align-center;
	}
	& > .scroll-arrow {
		position: absolute;
			bottom: 3rem;
			left: 0%;
		width: 100%;
	}
	&.-short {
		height: auto;
	}
	&.-full {
		height: 100vh;
		max-height: 900px;
	}
	& > .content {
		position: relative;
		z-index: 2;
	}
	& > .overlay {
		position: absolute;
			top: 0;
			left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		background: #000;
		opacity: .3;
		
		&.-white {
			background: #fff;
			opacity: .7;
		}
	}
}

.scroll-arrow {
	text-align: center;
	& > img {
		width: 35px;
		margin: 0 auto;
		margin-bottom: 1rem;
	}
}

.floating-box {
	background: #fff;
	padding: 4rem;
	width: 90%;
	max-width: 45rem;
	text-align: center;
	margin-top: 85px;
}

@media only screen and (min-width: 640px) {
	.floating-box {
		width: 80%;
	}
}