.path-container {
	padding-top: 0px;
	padding-bottom: 0px;
	overflow: hidden;
	height: auto;
	transition-delay: 1.2s;
}

.inner-section,
.inner-section-drop {
	// clip-path: polygon(0% 0%, 200% 0%, 200% 0%, 0% 0%);

	/*&.-shown {
		clip-path: polygon(0% 0%, 200% 0%, 200% 200%, 0% 200%);
	}*/
}

.inner-section-drop {
	max-height: 0px;
	transition: all 2s ease-out;
	overflow: hidden;
	background: $grey;

	&.-clip-path {
		clip-path: polygon(0% 0%, 200% 0%, 200% 200%, 0% 200%);
	}
	
	section {
		background: #fff;
		clip-path: polygon(0% 0%, 200% 0%, 200% 0%, 0% 0%);
		transition: all 0s ease-out 2s;
	}
	
	&.-shown {
		max-height: 2400px;
		
		section {
			clip-path: polygon(0% 0%, 200% 0%, 200% 200%, 0% 200%);
			transition: all 1s ease-out .2s;
		}
	}
}

.benefits-container {
	display: none;
	
	&.-active {
		display: block;
	}
}