.section-container {
	@extend .grid-x;
	background: #fff;
	
	padding: 3rem 5vw;
	
	&.-narrow {
		padding: 3rem 5vw;
		
		& > .half-block {
			padding: 0rem 5vw;
		}
	}
	&.-no-narrow-small {
		padding: 3rem 0vw;
	}
	&.-flush {
		padding: 3rem 0vw;
	}
	&.-full-padded {
		padding-top: 6rem;
		padding-bottom: 6rem;
	}
	&.-padded-top {
		padding-top: 6rem;
	}
	&.-padded-bot {
		padding-bottom: 6rem;
	}
}

.half-block {
	@extend .cell;
	width: 100%;
	
	padding: 0rem 5vw;

	&.-image-right {
		background-size: contain;
		background-repeat: no-repeat;
		background-position: right center;
	}
	&.-image-left {
		background-size: contain;
		background-repeat: no-repeat;
		background-position: left center;
	}
}

.full-block {
	@extend .cell;
	@extend .small-12;
	padding: 0rem 10vw;
	
	&.-narrow {
		padding: 0rem 10vw;
	}
}

.padded-block {
	padding: 0rem 2.5vw;
	margin-bottom: 5rem;
	
	&:last-of-type {
		margin-bottom: 0rem;
	}
}

.parallax-container {
	position: fixed;
	height: 100vh;
	width: 100vw;
	z-index: -1;
	
	& > .parallax {
		position: absolute;
		width: 100%;
	}
	
}
.parallax-spacer {
	height: 100vh;
}

@media only screen and (min-width: 640px) {
	
	.section-container {
		&.-narrow {
			padding: 3rem 10vw;
		}
		&.-no-narrow-small {
			padding: 3rem 10vw;
		}
	}
	
	.half-block {
		width: 50%;
		&.-flush-right {
			padding-right: 0px;
		}
		&.-flush-left {
			padding-left: 0px;
		}
		&.-flush {
			padding-left: 0px;
			padding-right: 0px;
		}
	}
	
	.padded-block {
		margin-bottom: 0rem;
	}
	
}

@media only screen and (min-width: 1026px) {

	.full-block {
		&.-narrow {
			padding: 0rem 20vw;
		}
	}
	
}