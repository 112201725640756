.content-block {
	
	margin: 0px -5vw;
	padding: 0rem 10vw;
	padding-top: 6rem !important;
	
	& > .body {
		margin-top: 3rem;
	}
	
	& > .db-image {
		margin-top: 3rem;
	}
	
	& > div {
		padding: 0px 0vw;
	}
	
	h2 {
		margin-bottom: 2rem;
	}
}

.line-callout {
	margin-top: 3rem;
	& > .container {
		border-left: 1px solid $navy;
		padding: 1rem 3rem;
	}
}

@media only screen and (min-width: 640px) {
	.content-block {
		& > div {
			padding: 0px 5vw;
		}
	}
}