.D\(ib\) {
  display: inline-block;
}
.D\(b\) {
  display: block;
}
.Fw\(400\) {
  font-weight: 400;
}
.Fz\(2\.2rem\) {
  font-size: 2.2rem;
}
.Fz\(2\.3rem\) {
  font-size: 2.3rem;
}
.Fz\(1\.3rem\) {
  font-size: 1.3rem;
}
.Fz\(1\.5rem\) {
  font-size: 1.5rem;
}
.Fz\(4rem\) {
  font-size: 4rem;
}
.Lh\(1\.2em\) {
  line-height: 1.2em;
}
.Mx\(1rem\) {
  margin-left: 1rem;
  margin-right: 1rem;
}
.Mx\(a\) {
  margin-left: auto;
  margin-right: auto;
}
.My\(1rem\), .Mt\(1rem\) {
  margin-top: 1rem;
}
.My\(1rem\), .Mb\(1rem\) {
  margin-bottom: 1rem;
}
.My\(2rem\), .Mt\(2rem\) {
  margin-top: 2rem;
}
.My\(2rem\), .Mb\(2rem\) {
  margin-bottom: 2rem;
}
.Mt\(5rem\) {
  margin-top: 5rem;
}
.Mt\(3rem\) {
  margin-top: 3rem;
}
.Mt\(4rem\) {
  margin-top: 4rem;
}
.Mb\(\.5rem\) {
  margin-bottom: .5rem;
}
.Mb\(0px\)\! {
  margin-bottom: 0px !important;
}
.Mb\(0rem\) {
  margin-bottom: 0rem;
}
.Mb\(0rem\)\! {
  margin-bottom: 0rem !important;
}
.Mb\(-2px\) {
  margin-bottom: -2px;
}
.Mb\(1rem\)\! {
  margin-bottom: 1rem !important;
}
.Mb\(3rem\) {
  margin-bottom: 3rem;
}
.Mb\(4rem\) {
  margin-bottom: 4rem;
}
.Mb\(5rem\) {
  margin-bottom: 5rem;
}
.Maw\(28em\) {
  max-width: 28em;
}
.T\(2rem\) {
  top: 2rem;
}
.T\(3rem\) {
  top: 3rem;
}
.End\(3rem\) {
  right: 3rem;
}
.End\(10vw\) {
  right: 10vw;
}
.End\(5vw\) {
  right: 5vw;
}
.B\(2rem\) {
  bottom: 2rem;
}
.B\(3rem\) {
  bottom: 3rem;
}
.B\(4rem\) {
  bottom: 4rem;
}
.Start\(10vw\) {
  left: 10vw;
}
.Start\(3rem\) {
  left: 3rem;
}
.Start\(5vw\) {
  left: 5vw;
}
.P\(6rem\) {
  padding: 6rem;
}
.Px\(0px\)\! {
  padding-left: 0px !important;
}
.Px\(0px\)\!, .Pend\(0px\)\! {
  padding-right: 0px !important;
}
.Px\(10\%\) {
  padding-left: 10%;
  padding-right: 10%;
}
.Px\(10vw\) {
  padding-left: 10vw;
  padding-right: 10vw;
}
.Px\(1rem\), .Pstart\(1rem\) {
  padding-left: 1rem;
}
.Px\(1rem\), .Pend\(1rem\) {
  padding-right: 1rem;
}
.Px\(5vw\) {
  padding-left: 5vw;
  padding-right: 5vw;
}
.Px\(8vw\) {
  padding-left: 8vw;
}
.Px\(8vw\), .Pend\(8vw\) {
  padding-right: 8vw;
}
.Py\(10rem\) {
  padding-top: 10rem;
}
.Py\(10rem\), .Pb\(10rem\) {
  padding-bottom: 10rem;
}
.Py\(3rem\)\! {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.Py\(6rem\) {
  padding-top: 6rem;
}
.Py\(6rem\), .Pb\(6rem\) {
  padding-bottom: 6rem;
}
.Py\(8rem\)\!, .Pt\(8rem\)\! {
  padding-top: 8rem !important;
}
.Py\(8rem\)\! {
  padding-bottom: 8rem !important;
}
.Py\(5rem\) {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.Pt\(0rem\)\! {
  padding-top: 0rem !important;
}
.Pt\(10rem\)\! {
  padding-top: 10rem !important;
}
.Pt\(12rem\) {
  padding-top: 12rem;
}
.Pt\(0px\)\! {
  padding-top: 0px !important;
}
.Pt\(20vh\) {
  padding-top: 20vh;
}
.Pt\(25vh\) {
  padding-top: 25vh;
}
.Pt\(25vh\)\! {
  padding-top: 25vh !important;
}
.Pt\(6rem\)\! {
  padding-top: 6rem !important;
}
.Pt\(15vh\) {
  padding-top: 15vh;
}
.Pend\(15\%\) {
  padding-right: 15%;
}
.Pend\(4rem\) {
  padding-right: 4rem;
}
.Pb\(\.5rem\)\! {
  padding-bottom: .5rem !important;
}
.Pb\(0rem\) {
  padding-bottom: 0rem;
}
.Pb\(0rem\)\! {
  padding-bottom: 0rem !important;
}
.Pb\(12rem\)\! {
  padding-bottom: 12rem !important;
}
.Pb\(3rem\) {
  padding-bottom: 3rem;
}
.Pb\(6rem\)\! {
  padding-bottom: 6rem !important;
}
.Pstart\(2rem\) {
  padding-left: 2rem;
}
.Pstart\(3vw\) {
  padding-left: 3vw;
}
.Pos\(r\) {
  position: relative;
}
.Pos\(a\) {
  position: absolute;
}
.Ta\(end\) {
  text-align: right;
}
.Ta\(start\) {
  text-align: left;
}
.Tt\(u\) {
  text-transform: uppercase;
}
.W\(100\%\) {
  width: 100%;
}
.W\(300px\) {
  width: 300px;
}
.W\(45px\) {
  width: 45px;
}
.W\(70px\) {
  width: 70px;
}
.W\(75px\) {
  width: 75px;
}
@media screen and (max-width: 640px) {
  .Mt\(2rem\)--sm {
    margin-top: 2rem;
  }
  .Mt\(3rem\)--sm {
    margin-top: 3rem;
  }
  .Mt\(0px\)\!--sm {
    margin-top: 0px !important;
  }
  .Mb\(0rem\)\!--sm {
    margin-bottom: 0rem !important;
  }
  .Mb\(1rem\)--sm {
    margin-bottom: 1rem;
  }
  .Mb\(2rem\)--sm {
    margin-bottom: 2rem;
  }
  .Mb\(3rem\)--sm {
    margin-bottom: 3rem;
  }
  .Px\(5vw\)--sm {
    padding-left: 5vw;
    padding-right: 5vw;
  }
  .Pt\(1rem\)\!--sm {
    padding-top: 1rem !important;
  }
  .Pb\(4rem\)--sm {
    padding-bottom: 4rem;
  }
  .Ta\(end\)--sm {
    text-align: right;
  }
  .Ta\(start\)--sm {
    text-align: left;
  }
}
@media screen and (min-width: 640px) {
  .D\(ib\)--md {
    display: inline-block;
  }
  .Fz\(7vw\)--md {
    font-size: 7vw;
  }
  .Mt\(2rem\)--md {
    margin-top: 2rem;
  }
  .Mend\(1rem\)--md {
    margin-right: 1rem;
  }
  .Mb\(0rem\)\!--md {
    margin-bottom: 0rem !important;
  }
  .Maw\(30em\)--md {
    max-width: 30em;
  }
  .Maw\(600px\)--md {
    max-width: 600px;
  }
  .End\(10vw\)--md {
    right: 10vw;
  }
  .Start\(10vw\)--md {
    left: 10vw;
  }
  .Px\(10vw\)--md {
    padding-left: 10vw;
    padding-right: 10vw;
  }
  .Py\(6rem\)\!--md {
    padding-top: 6rem !important;
  }
  .Py\(6rem\)\!--md, .Pb\(6rem\)\!--md {
    padding-bottom: 6rem !important;
  }
  .Pt\(20vh\)--md {
    padding-top: 20vh;
  }
  .Pt\(6rem\)--md {
    padding-top: 6rem;
  }
  .Pb\(12rem\)--md {
    padding-bottom: 12rem;
  }
  .Pb\(1rem\)\!--md {
    padding-bottom: 1rem !important;
  }
  .Pstart\(4rem\)--md {
    padding-left: 4rem;
  }
  .Pstart\(8vw\)--md {
    padding-left: 8vw;
  }
  .Ta\(c\)--md {
    text-align: center;
  }
  .Ta\(end\)--md {
    text-align: right;
  }
  .Ta\(start\)--md {
    text-align: left;
  }
  .W\(50\%\)--md {
    width: 50%;
  }
}
@media screen and (min-width: 1024px) {
  .Pt\(25vh\)--lg {
    padding-top: 25vh;
  }
}
