.blog-nav {
	padding: 6rem 8vw;
	
	.link {
		margin-left: 2rem;
		color: $dark-grey;
		&:after {
			background: $dark-grey;
		}
	}
}
.nav-drop {
	margin-top: 3rem;
	.link {
		display: block;
		margin-left: 0px;
		color: #444;
		padding: .45rem .9rem;
		background: $grey;
		margin-bottom: 0px;
		
		&:first-of-type {
			padding-top: .9rem;
		}
		&:last-of-type {
			padding-bottom: .9rem;
		}
		&:after {
			display: none;
		}
	}
	.filter {
		color: #fff;
		font-size: 1.2rem;
		background: $dark-grey;
		padding: .9rem;
		margin-bottom: 0px;
	}
}

.article-grid {
	padding: 8vw;
	padding-top: 0px;
	padding-bottom: 0rem;
	margin-left: -2rem;
	margin-right: -2rem;
	
	& > .article {
		margin-bottom: 4rem;
	}
}

$gutter-width: 2rem;
.article {
	width: calc(100% - 4rem);
	margin-left: $gutter-width;
	margin-right: $gutter-width;
	& > img {
		margin-bottom: 2rem;
		width: 100%;
	}
	& > p {
		margin-top: 1rem;
		margin-bottom: 1rem;
	}
}

.blog-paginate {
	@extend .grid-x;
	padding: 0rem;
	padding-bottom: 6rem;
	.arrow {
		width: 10px;
		margin-right: 1rem;
		margin-left: 1rem;
	}
}
.blog-numbers {
	& > .link {
		font-family: "Coromant Garamond", serif;
		color: $dark-grey;
		margin-left: 1rem;
		margin-right: 1rem;
		
		&:after {
			opacity: 0;
		}
		
		&:hover,
		&.-active {
			color: $orange;
			
			&:after {
				opacity: 1;
				transform: translateY(0px);
				background: $orange;
			}
		}
	}
}

.blog-article {
	margin-bottom: 4rem;
	
	& > img {
		width: 100%;
		margin-bottom: 2rem;
	}
	& > h2 {
		margin-bottom: 2rem;
	}
}

@media only screen and (min-width: 1026px) {
	
	.article {
		width: calc(50% - 4rem);
	}
	
}

@media only screen and (min-width: 640px) {
	
	.article {
		width: calc(33% - 4rem);
	}
	.article-grid {
		padding: 15vw;
		padding-bottom: 6rem;
	}
	.blog-nav {
		padding: 6rem 15vw;
	}
	.blog-article {
		margin-bottom: 0rem;
	}
	
}